export default function pageFormularioRespostaScripts() {
	function slideToForm() {
		const { hash } = window.location;
		if (!hash.includes("#form")) return;

		const formSection = document.querySelector("#form");
		setTimeout(() => window.scrollTo(0, formSection.offsetTop), 1000);
	}

	document.addEventListener("DOMContentLoaded", () => {
		slideToForm();
		console.log("AQUI O WINDOW 2", window.swiperFormularioRef);
		window.swiperFormularioRef.slideTo(0);
	});

	
}
