window.listOfIMask = [];

export default function IMaskInit() {
	console.log("MASK INIT", window.hasOwnProperty("IMask"));
	if (!window.hasOwnProperty("IMask")) return;

	const IMaskOpt = {
		date: {
			mask: "DD{/}MM{/}0000",
			blocks: {
				DD: {
					mask: IMask.MaskedRange,
					from: 1,
					to: 31,
				},
				MM: {
					mask: IMask.MaskedRange,
					from: 1,
					to: 12,
				},
			},
		},

		real: {
			mask: "R$ num",
			blocks: {
				num: {
					mask: Number,
					scale: 2,
					thousandsSeparator: ".",
					padFractionalZeros: true,
					normalizeZeros: true,
					radix: ",",
				},
			},
		},
	};

	const masks = {
		"imask-phone": (field) => IMask(field, { mask: "+{55} (00) 00000-0000" }),
		"imask-number": (field) => IMask(field, { mask: Number }),
		"imask-agencia": (field) => IMask(field, { mask: "0000" }),
		"imask-cep": (field) => IMask(field, { mask: "00000-000" }),
		"imask-date": (field) => IMask(field, IMaskOpt.date),
		"imask-real": (field) => IMask(field, IMaskOpt.real),
		"imask-cpf": (field) => IMask(field, { mask: "000.000.000-00" }),
	};

	function applyMask(selector) {
		const maskActive = masks[selector];
		const fields = document.querySelectorAll(`[${selector}]`);

		fields?.forEach((field) => {
			if (maskActive && !field.classList.contains("IMask-on")) {
				const mask = maskActive(field);
				window.listOfIMask.push(mask);
				field.classList.add("IMask-on");
			}
		});
	}

	Object.keys(masks).map(applyMask);
}
