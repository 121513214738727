export default function tabs() {
	const faqHeadingItens = document.querySelectorAll(
		".faq-wrapper .faq-item .faq-heading"
	);

	const removeActive = (el) => {
		el.querySelector("svg use").setAttribute("xlink:href", "#icon_plus-faq");
		el.classList.remove("active");
		el.classList.remove("active");
		el.classList.remove("active-in");
		const content = el.closest(".faq-item").querySelector(".content");
		content?.style.setProperty("--height", 0);
	};

	const addActive = (el) => {
		el.classList.add("active");
		const svgItem = el.querySelector("svg use");
		svgItem.setAttribute("xlink:href", "#icon_minus-faq");
		const content = el.querySelector(".content");
		console.log({content, el})
		const height = el.querySelector(".content .faq-text").scrollHeight;
		content?.style.setProperty("--height", height + "px");
	};

	function handleClick(e) {
		const liItem = e.target.closest(".faq-item");
		if (!liItem) return;
		const active = liItem.classList.contains("active");
		const single = liItem.closest("[data-single]");
		// for single open
		single &&
			faqHeadingItens.forEach((el) => removeActive(el.closest(".faq-item")));

		active ? removeActive(liItem) : addActive(liItem);
	}

	faqHeadingItens &&
		faqHeadingItens.forEach((el) => {
			el.addEventListener("click", handleClick);
			const item = el.closest(".faq-item");
			if (item.classList.contains("active")) {
				addActive(item);
			}
		});
}
