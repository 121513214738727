export default function animationHome() {
	gsap.registerPlugin(ScrollTrigger);

	function bannerInfo() {
		let infoBannerTimeline = gsap.timeline({
			scrollTrigger: {
				trigger: ".banner-infos",
				start: "top+=50px bottom",
			},
		});
		gsap.set(".banner-infos", { clipPath: "circle(0%)", opacity: 1 });

		infoBannerTimeline.to(".banner-infos", {
			duration: 2,
			opacity: 1,
			clipPath: "circle(100%)",
			delay: 0.5,
		});
	}

	function comoFunciona() {
		gsap.set("#como-funciona .grid-wrapper .col-1 .woman-img", { x: "-20px" });
		gsap.set("#como-funciona .grid-wrapper .col-1 .device-img", { x: "20px" });
		gsap.set("#como-funciona .grid-wrapper .col-1 .icons-img", {
			y: "100px",
			x: "20px",
			clipPath: "circle(0%)",
			scale: 0,
		});

		// gsap.set('.banner-infos',{clipPath: "circle(0%)", opacity:1});
		const trigger = document.querySelector(
			"#como-funciona .grid-wrapper .col-1 .woman-img"
		);
		gsap.to("#como-funciona .grid-wrapper .col-1 .woman-img", {
			x: "0",
			duration: 1,
			scrollTrigger: {
				trigger: trigger,
				start: "top center",
				end: "bottom top",

				scrub: 2,
			},
		});
		gsap.to("#como-funciona .grid-wrapper .col-1 .device-img", {
			x: "0",
			duration: 1,
			scrollTrigger: {
				trigger: trigger,
				start: "top center",
				end: "bottom top",

				scrub: 2,
			},
		});

		gsap.to("#como-funciona .grid-wrapper .col-1 .icons-img", {
			y: "0",
			x: "0",
			clipPath: "circle(100%)",
			scale: 1,
			duration: 1,
			scrollTrigger: {
				trigger: trigger,
				start: "top center",
				end: "top center",

				scrub: 2,
			},
		});
	}

	function ctaRedesSociais() {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: "#cta-whatsapp.redes-sociais",
				start: "top+=150px 80%",
				end: "bottom",
				// markers: true,
				once: false,
			},
			defaults: { ease: Power1.easeOut },
		});

		tl.fromTo(
			"#cta-whatsapp.redes-sociais",
			{ y: 100, opacity: 0 },
			{ y: 0, opacity: 1 }
		)
			.fromTo(
				"#cta-whatsapp.redes-sociais .info",
				{ clipPath: "inset(0 100% 0 0)" },
				{ clipPath: "inset(0 0% 0 0)" },
				"-=0.2"
			)
			//   .fromTo('#cta-whatsapp.redes-sociais .title-info',{clipPath: "inset(0 100% 0 0)"},{ clipPath: "inset(0 0% 0 0)"})
			.fromTo(
				"#cta-whatsapp.redes-sociais .social-icon",
				{ clipPath: "circle(0%)" },
				{ clipPath: "circle(100%)", stagger: 0.1 },
				"-=0.2"
			);
	}

	//function hoverBtns() {
		// let btns = document.querySelectorAll(".btn-wrapper-with-icons a, .btn-wrapper-with-icons button")
		// btns.forEach((button) => {
		//  let icon =  button.querySelector('.icon')
		//  let buttonRef =  gsap.to(button, {
		//     scale: 1.2,
		//     duration: 0.3,
		//     ease: "power1.inOut",
		//     paused: true,
		//     // onEnter: () => {
		//     //   gsap.to(button, {
		//     //     scale: 1.5,
		//     //     duration: 0.3,
		//     //     ease: "power1.inOut",
		//     //   });
		//     // },
		//     // onLeave: () => {
		//     //   gsap.to(button, {
		//     //     scale: 1.2,
		//     //     duration: 0.3,
		//     //     ease: "power1.inOut",
		//     //   });
		//     // },
		//   });
		//   button.addEventListener("mouseenter", () => {
		//     buttonRef.play();
		//   });
		//   button.addEventListener("mouseleave", () => {
		//     buttonRef.reverse();
		//   });
		// });
		// function animate(elem){
		//   hoverTL.to( elem , { background: ' #4E9E37' , scale: 2})
		// }
		// // gsap.to('.btn-wrapper-with-icons a', {scale: 1.12, color: "blue", duration: 1.5, paused: true, ease: "power1.inOut"});
		// console.log(btns)
		// btns.forEach(btn => {
		//   btn.addEventListener("mouseenter", () => {
		//     console.log('aaaaaa')
		//     animate(btn);
		//     hoverTL.play()
		//   });
		// })
		// btns.forEach(btn => {
		//   btn.addEventListener("mouseleave", () => hoverTL.reverse());
		// })
	//}

	function animateClickSimuleScrool() {
		const bntSimulate = document.querySelector(
			'#menu .link[href="/#simulador"]'
		);
    	const simulatorBox = document.querySelector('#simulador')

		if (!bntSimulate || !simulatorBox) return;

		function handleClickScrollTo(e) {
			e.preventDefault();

      		simulatorBox.scrollIntoView({
        		behavior: 'smooth'
      		})

			const isMobile = window.matchMedia('(max-width: 1100px)').matches;

			if(isMobile){
				const btnClose = document.querySelector('#btn-mobile[aria-label="Fechar menu"]')
				btnClose.click()
			}
		}

		bntSimulate.addEventListener("click", handleClickScrollTo);
	}

	document.addEventListener("DOMContentLoaded", function () {
		// hovecrBtns();
		bannerInfo();
		comoFunciona();
		ctaRedesSociais();
		animateClickSimuleScrool();
	});
	//   bannerParallaxTL.

	// ScrollTrigger.create({
	//     trigger: infoBanner,
	//     start: 'top center', // inicia a animação quando o centro do elemento de texto atinge o topo da viewport
	//     end: 'bottom center', // finaliza a animação quando o centro do elemento de texto atinge a parte inferior da viewport
	//     once: true
	// })

	// gsap.from(infoBanner,{ duration: 2 , clipPath: "circle(100%)"})
}
