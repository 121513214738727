
export default function globalSwipers(){


    const swiperBannerInfos = new Swiper('.swiper-infos-banner', {
        slidesPerView: 'auto',
        loop: true,
        allowTouchMove: false,
        alowtouch: false,
        speed: 2000,
        // freemode: true,
        breakpoints:{
            1024:{
                slidesPerView: 8
            }
        },
        autoplay: {
            delay:0,
            pauseOnMouseEnter: true,
            disableOnInteraction: false
          },
    })

   
    
    const swiperTabsFormulario = new Swiper('.swiper-tabs-formulario',{
        slidesPerView: 'auto',
    })
    window.swiperFormularioRef =   swiperTabsFormulario;
}