import IMaskInit from "../IMask";
import moment from "moment/moment";

export default async function pageFormularioScripts() {
	let data = {};

	const showToastify = (message, props) => {
		Toastify({
			...this.toastDefault,

			text: message,
			duration: 3500,
			...props,
		}).showToast();
	};

	existOtherCorban = window.location.pathname.split("/");
	if (existOtherCorban[2]) {
		document.querySelector("#banner-all-pages").remove();
		document.querySelector("#footer").remove();
		document.querySelector("#header").remove();
		document.querySelector("#cta-whatsapp").remove();
		document.querySelector("#form").style =
			"padding-top: 4rem !important; padding-bottom: 4rem;";
	}

	let userID;

	const corban = document.querySelector("input[name='corban']").value;
	const taxa_juros = document.querySelector("input[name='taxa_juros']").value;
	[".conta-corrente-col-1", ".conta-corrente-col-2", ".conta-pix-col-1"].map(
		(name) => {
			const section = document.querySelector(name);

			if (section) {
				section.style.display = "none";
			}
		}
	);
	const nextBtns = document.querySelectorAll(".btn-wrapper-with-icons.next");
	const prevBtns = document.querySelectorAll(".btn-wrapper-with-icons.prev");
	const allContentTabs = document.querySelectorAll(".form-base");
	const allTabs = document.querySelectorAll(
		".swiper-tabs-formulario .swiper-slide .tab-item"
	);
	const allTabsConfirmacao = document.querySelectorAll(
		".swiper-tabs-confirmacao .swiper-slide .tab-item"
	);
	let bancos = null;
	fetch("/api/banco/?limit=9999").then(async (response) => {
		const data = await response.json();
		bancos = data.results;
	});

	const cepInput = document.querySelector(".cep-wrapper");

	let currentStep = 0;

	function activeTab(step) {
		if (allTabs[step]) {
			window.swiperFormularioRef.slideTo(step);

			allTabs.forEach((tab) => tab.classList.remove("active"));
			allTabs[step].classList.add("active");
		}
	}

	function activeTabConfirmacao(step) {
		if (allTabsConfirmacao[step]) {
			allTabsConfirmacao.forEach((tab) => tab.classList.remove("active"));
			allTabsConfirmacao[step].classList.add("active");
		}
	}

	nextBtns.forEach((next) => {
		next.addEventListener("click", async (e) => {
			const button = e.target.querySelector("button");

			if (button?.disabled) return;
			e.preventDefault();
			if (allContentTabs.length !== currentStep) {
				if (!userID) {
					await fetch("/api/naturalperson/", {
						method: "POST",
						body: JSON.stringify(data),
						headers: {
							"Content-type": "application/json; charset=UTF-8",
							Authorization: `Token ${process.env.TOKEN}`,

						},
						mode: "cors",
					})
						.then(async (response) => {
							if (!String(response.status).startsWith(2)) {
								showToastify(
									`Erro na comunicação com servidor, tente novamente mais tarde.`,
									{
										style: {
											background: "#f80000",
										},
									}
								);
								return;
							}
							currentStep++;
							response = await response.json();
							userID = response.id;
						})
						.catch((err) => {
							console.log(err);
							showToastify(
								`Erro na comunicação com servidor, tente novamente mais tarde.`,
								{
									style: {
										background: "#f80000",
									},
								}
							);
							return;
						});
				} else {
					await fetch(`/api/naturalperson/${userID}/`, {
						method: "PUT",
						body: JSON.stringify(data),
						headers: {
							"Content-type": "application/json; charset=UTF-8",
							Authorization: `Token ${process.env.TOKEN}`,

						},
						mode: "cors",
					})
						.then(async (response) => {
							if (!String(response.status).startsWith(2)) {
								showToastify(
									`Erro na comunicação com servidor, tente novamente mais tarde.`,
									{
										style: {
											background: "#f80000",
										},
									}
								);
								return;
							}
							currentStep++;
						})
						.catch((err) => {
							console.log(err);
							showToastify(
								`Erro na comunicação com servidor, tente novamente mais tarde.`,
								{
									style: {
										background: "#f80000",
									},
								}
							);
							return;
						});
				}

				if (allContentTabs[currentStep]) {
					allContentTabs.forEach((formStep) =>
						formStep.classList.remove("active")
					);
					allContentTabs[currentStep].classList.add("active");
				}
			} else {
				return;
			}
			activeTab(currentStep);
		});
	});

	prevBtns.forEach((prev) => {
		prev.addEventListener("click", (e) => {
			e.preventDefault();

			if (currentStep === 0) {
				currentStep = 0;
				return;
			} else if (currentStep >= 1) {
				currentStep--;

				if (allContentTabs[currentStep]) {
					allContentTabs.forEach((formStep) =>
						formStep.classList.remove("active")
					);
					allContentTabs[currentStep].classList.add("active");
				}
			}
			activeTab(currentStep);
		});
	});

	allTabsConfirmacao.forEach((tab) => {
		tab.addEventListener("click", (e) => {
			activeTab(currentStep);
		});
	});

	const isValidCep = {
		error: false,
		cepError: "",
	};

	function checkCEP(cep) {
		const cepRegex = /^\d{5}-?\d{3}$/;

		if (cepRegex.test(cep)) {
			return true; // CEP válido
		} else if (!cep) {
			isValidCep.error = true;
			isValidCep.cepError = "Informe um CEP válido";
			return false;
		} else {
			isValidCep.error = true;
			isValidCep.cepError = "Número do CEP inválido";
			return false; // CEP inválido
		}
	}

	function clearFields() {
		const fieldsAddress = document.querySelectorAll(
			'.form-base[data-step="3"] input'
		);
		const clearCamps = ["bairro", "localidade", "logradouro", "uf"];
		fieldsAddress.forEach((field) => {
			if (clearCamps.includes(field.dataset.field)) {
				field.value = "";
			}
		});
	}

	function fillInputs(address) {
		const fieldsAddress = document.querySelectorAll(
			'.form-base[data-step="2"] input'
		);

		fieldsAddress.forEach((field) => {
			for (const property in address) {
				if (field.dataset.field === property) {
					field.value = address[property];
					data = {
						...data,
						[property]: address[property],
					};
				}
			}
		});
	}

	async function getAddress(cep) {
		const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
		const data = await response.json();
		let summaryData = {
			...data,
			neighborhood: data.bairro || "",
			street: data.logradouro || "",
			state: data.uf || "",
			city: data.localidade || "",
			complement: data.complemento || "",
		};
		fillInputs(summaryData);
	}

	cepInput?.addEventListener("input", (e) => {
		const cep = e.target.value;

		if (e.target.value === "") {
			clearFields();
			return;
		}
		const isValid = checkCEP(cep);

		if (isValid) {
			getAddress(cep);
		}
	});

	const formulario = document.querySelector("#formulario");
	formulario.addEventListener("keydown", function (event) {
		if (event.key === "Enter") {
			event.preventDefault();
		}
	});

	const summaryMask = {
		CPF: "imask-cpf",
		Telefone: "imask-phone",
	};

	formulario.addEventListener("input", (e) => {
		e.preventDefault();
		console.log("AUI O INPUT CON", e.target.name);
		// document.get
		let params = new URLSearchParams(document.location.search);
		// const total_desejado = params.get("valor_desejado");
		// if (total_desejado) {
		// 	data = {
		// 		...data,
		// 		total_desejado,
		// 	};
		// }

		// if (e.target.name == "conta_pix") {
		// 	if (e.target.value == "Conta Corrente") {
		// 		document.querySelector(".conta-pix-col-1").style.display = "none";
		// 		[".conta-corrente-col-1", ".conta-corrente-col-2"].map((name) => {
		// 			const section = document.querySelector(name);
		// 			if (section) {
		// 				section.style.display = "block";
		// 			}
		// 		});
		// 	} else if (e.target.value == "Pix") {
		// 		document.querySelector(".conta-pix-col-1").style.display = "block";

		// 		[".conta-corrente-col-1", ".conta-corrente-col-2"].map((name) => {
		// 			const section = document.querySelector(name);
		// 			if (section) {
		// 				section.style.display = "none";
		// 			}
		// 		});
		// 	} else {
		// 		[
		// 			".conta-corrente-col-1",
		// 			".conta-corrente-col-2",
		// 			".conta-pix-col-1",
		// 		].map((name) => {
		// 			const section = document.querySelector(name);

		// 			if (section) {
		// 				section.style.display = "none";
		// 			}
		// 		});
		// 	}
		// }

		// if (e.target.name == "tipo_chave_pix") {
		// 	const tipoChavePix = document.querySelector("#tipo_chave_pix");

		// 	if (tipoChavePix.value) {
		// 		const groupPix = document.querySelector("#group-pix");
		// 		groupPix.style.display = "block";
		// 		document.querySelector("label#label_pix").innerHTML =
		// 			tipoChavePix.value;

		// 		const hasInputPix = document.getElementById("pix");
		// 		if (hasInputPix) {
		// 			hasInputPix.remove();
		// 		}
		// 		const inputPix = document.createElement("input");
		// 		inputPix.name = "pix";
		// 		inputPix.id = "pix";
		// 		inputPix.type = "text";

		// 		if (tipoChavePix.value == "CPF") {
		// 			inputPix.value = data.document_number;
		// 			data = {
		// 				...data,
		// 				[e.target.name]: e.target.value,
		// 				pix: data.document_number,
		// 			};
		// 		}

		// 		if (tipoChavePix.value == "Telefone") {
		// 			inputPix.value = data.phone;
		// 			data = {
		// 				...data,
		// 				[e.target.name]: e.target.value,
		// 				pix: data.phone,
		// 			};
		// 		}

		// 		if (summaryMask[tipoChavePix.value])
		// 			inputPix.setAttribute(summaryMask[tipoChavePix.value], true);
		// 		groupPix.appendChild(inputPix);

		// 		IMaskInit();
		// 	} else {
		// 		document.querySelector("#group-pix").style.display = "none";
		// 	}
		// }

		if (e.target.name == "birth_date") {
			let dateEN = moment(e.target.value, "DD/MM/YYYY").format("YYYY-MM-DD");

			data = {
				...data,
				[e.target.name]: dateEN,
				corban,
				taxa_juros,
				status: "autoatendimento",
				status_antifraude: "autoatendimento",
			};
		} else {
			data = {
				...data,
				[e.target.name]: e.target.value,
				corban,
				taxa_juros,
				status: "autoatendimento",
				status_antifraude: "autoatendimento",
			};
		}

		// REQUIREMENTS STEP 01
		const requirementsStepOne = [
			"name",
			"document_number",
			"phone",
			"birth_date",
			"mother_name",
		].every((name) => data[name]);
		const buttonOne = document.querySelector("button[data-next='1']");
		const inputCPFStepOne = document.querySelector(
			"input[name='document_number']"
		);

		const inputPhoneStepOne = document.querySelector("input[name='phone']");
		buttonOne.disabled =
			!requirementsStepOne ||
			inputPhoneStepOne.value.length < 19 ||
			inputCPFStepOne.value.length < 14;

		if (
			!requirementsStepOne ||
			inputPhoneStepOne.value.length < 19 ||
			inputCPFStepOne.value.length < 14
		) {
			buttonOne.style.pointerEvents = "none";
			buttonOne.style.opacity = 0.7;
		} else {
			buttonOne.style.pointerEvents = "auto";
			buttonOne.style.opacity = 1;
		}

		// REQUIREMENTS STEP 02
		const requirementsStepTwo = [
			"postal_code",
			"street",
			"number",
			// "complement",
			"neighborhood",
			"state",
			"city",
		].every((name) => data[name]);
		const buttonTwo = document.querySelector("button[data-next='2']");
		buttonTwo.disabled = !requirementsStepTwo;
		if (!requirementsStepTwo) {
			buttonTwo.style.pointerEvents = "none";
			buttonTwo.style.opacity = 0.7;
		} else {
			buttonTwo.style.pointerEvents = "auto";
			buttonTwo.style.opacity = 1;
		}

		// REQUIREMENTS STEP 03
		let requirementsStepThree = [];
		let status = false;
		if (data.conta_pix == "Conta Corrente") {
			requirementsStepThree = [
				"banco",
				"agencia",
				"conta",
				"digito_verificador",
			];
		} else {
			requirementsStepThree = ["tipo_chave_pix", "pix"];
			let inputPix = document.querySelector("input[name='pix']");
			if (data.tipo_chave_pix == "CPF") {
				status = inputPix.value?.length < 14;
			} else if (data.tipo_chave_pix == "Telefone") {
				status = inputPix.value?.length < 19;
			}
		}

		requirementsStepThree = requirementsStepThree.every((name) => data[name]);
		const buttonThree = document.querySelector("button[data-next='3']");
		buttonThree.disabled = !requirementsStepThree || status;
		if (!requirementsStepThree || status) {
			buttonThree.style.pointerEvents = "none";
			buttonThree.style.opacity = 0.7;
		} else {
			buttonThree.style.pointerEvents = "auto";
			buttonThree.style.opacity = 1;
		}
	});

	//
	formulario.addEventListener("change", (e) => {
		e.preventDefault();
		if (e.target.name == "conta_pix") {
			if (e.target.value == "Conta Corrente") {
				document.querySelector(".conta-pix-col-1").style.display = "none";
				[".conta-corrente-col-1", ".conta-corrente-col-2"].map((name) => {
					const section = document.querySelector(name);
					if (section) {
						section.style.display = "block";
					}
				});
			} else if (e.target.value == "Pix") {
				document.querySelector(".conta-pix-col-1").style.display = "block";

				[".conta-corrente-col-1", ".conta-corrente-col-2"].map((name) => {
					const section = document.querySelector(name);
					if (section) {
						section.style.display = "none";
					}
				});
			} else {
				[
					".conta-corrente-col-1",
					".conta-corrente-col-2",
					".conta-pix-col-1",
				].map((name) => {
					const section = document.querySelector(name);

					if (section) {
						section.style.display = "none";
					}
				});
			}
		}

		if (e.target.name == "tipo_chave_pix") {
			const tipoChavePix = document.querySelector("#tipo_chave_pix");

			if (tipoChavePix.value) {
				const groupPix = document.querySelector("#group-pix");
				groupPix.style.display = "block";
				document.querySelector("label#label_pix").innerHTML =
					tipoChavePix.value;

				const hasInputPix = document.getElementById("pix");
				if (hasInputPix) {
					hasInputPix.remove();
				}
				const inputPix = document.createElement("input");
				inputPix.name = "pix";
				inputPix.id = "pix";
				inputPix.type = "text";

				if (tipoChavePix.value == "CPF") {
					inputPix.value = data.document_number;
					data = {
						...data,
						[e.target.name]: e.target.value,
						pix: data.document_number,
					};
				}

				if (tipoChavePix.value == "Telefone") {
					inputPix.value = data.phone;
					data = {
						...data,
						[e.target.name]: e.target.value,
						pix: data.phone,
					};
				}

				if(tipoChavePix.value == "E-mail"){
					inputPix.addEventListener('input', (e) => {
						e.currentTarget.value = e.currentTarget.value.toLowerCase();
					})
				}

				if (summaryMask[tipoChavePix.value]){
					inputPix.setAttribute(summaryMask[tipoChavePix.value], true);
				}
				groupPix.appendChild(inputPix);

				IMaskInit();
			} else {
				document.querySelector("#group-pix").style.display = "none";
			}
		}

		if (e.target.name == "birth_date") {
			let dateEN = moment(e.target.value, "DD/MM/YYYY").format("YYYY-MM-DD");

			data = {
				...data,
				[e.target.name]: dateEN,
				corban,
				taxa_juros,
				status: "autoatendimento",
				status_antifraude: "autoatendimento",
			};
		} else {
			data = {
				...data,
				[e.target.name]: e.target.value,
				corban,
				taxa_juros,
				status: "autoatendimento",
				status_antifraude: "autoatendimento",
			};
		}

		// REQUIREMENTS STEP 01
		const requirementsStepOne = [
			"name",
			"document_number",
			"phone",
			"birth_date",
			"mother_name",
		].every((name) => data[name]);
		const buttonOne = document.querySelector("button[data-next='1']");
		const inputCPFStepOne = document.querySelector(
			"input[name='document_number']"
		);

		const inputPhoneStepOne = document.querySelector("input[name='phone']");
		buttonOne.disabled =
			!requirementsStepOne ||
			inputPhoneStepOne.value.length < 19 ||
			inputCPFStepOne.value.length < 14;

		if (
			!requirementsStepOne ||
			inputPhoneStepOne.value.length < 19 ||
			inputCPFStepOne.value.length < 14
		) {
			buttonOne.style.pointerEvents = "none";
			buttonOne.style.opacity = 0.7;
		} else {
			buttonOne.style.pointerEvents = "auto";
			buttonOne.style.opacity = 1;
		}

		// REQUIREMENTS STEP 02
		const requirementsStepTwo = [
			"postal_code",
			"street",
			"number",
			// "complement",
			"neighborhood",
			"state",
			"city",
		].every((name) => data[name]);
		const buttonTwo = document.querySelector("button[data-next='2']");
		buttonTwo.disabled = !requirementsStepTwo;
		if (!requirementsStepTwo) {
			buttonTwo.style.pointerEvents = "none";
			buttonTwo.style.opacity = 0.7;
		} else {
			buttonTwo.style.pointerEvents = "auto";
			buttonTwo.style.opacity = 1;
		}

		// REQUIREMENTS STEP 03
		let requirementsStepThree = [];
		let status = false;
		if (data.conta_pix == "Conta Corrente") {
			requirementsStepThree = [
				"banco",
				"agencia",
				"conta",
				"digito_verificador",
			];
		} else {
			requirementsStepThree = ["tipo_chave_pix", "pix"];
			let inputPix = document.querySelector("input[name='pix']");
			if (data.tipo_chave_pix == "CPF") {
				status = inputPix.value?.length < 14;
			} else if (data.tipo_chave_pix == "Telefone") {
				status = inputPix.value?.length < 19;
			}
		}

		requirementsStepThree = requirementsStepThree.every((name) => data[name]);
		const buttonThree = document.querySelector("button[data-next='3']");
		buttonThree.disabled = !requirementsStepThree || status;
		if (!requirementsStepThree || status) {
			buttonThree.style.pointerEvents = "none";
			buttonThree.style.opacity = 0.7;
		} else {
			buttonThree.style.pointerEvents = "auto";
			buttonThree.style.opacity = 1;
		}
	});

	formulario.addEventListener("submit", async (e) => {
		e.preventDefault();
		try {
		

			if (allContentTabs.length !== currentStep) {
				currentStep++;
				if (allContentTabs[currentStep]) {
					allContentTabs.forEach((formStep) =>
						formStep.classList.remove("active")
					);
					allContentTabs[currentStep].classList.add("active");
				}
			} else {
				return;
			}
			activeTab(currentStep);
		} catch (err) {
			console.log("ERROR", err);
		}
	});
}
