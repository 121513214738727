import Dom from "./modules/constructors.js"; //selecionar elementos
import menu from "./modules/menu.js";
import scriptsHome from "./modules/scriptsPaginas/home.js";
import IMaskInit from "./modules/IMask.js";

import globalSwipers from "./modules/global-swiper.js";
import pageFormularioScripts from "./modules/scriptsPaginas/formulario.js";
import pageFormularioRespostaScripts from "./modules/scriptsPaginas/formulario-resposta.js";
import tabs from "./modules/faq.js";
import { animaAoScroll } from "./modules/animation/animaAoScroll.js";
import animationHome from "./modules/animation/home/animationHome.js";
import globalAnimation from "./modules/animation/globalAnimation.js";
import animationSobre from "./modules/animation/home/animationSobre.js";
import animationContato from "./modules/animation/home/animateContato.js";
// let mediaQuery = window.matchMedia('(min-width: 1024px)').matches

// GLOBAL SCRIPTS
menu();
tabs();
globalSwipers();
animaAoScroll();
globalAnimation();
Fancybox.bind("[data-fancybox]");

// PAGES
const pageHome = new Dom().el("#page__home");
const pageSobre = new Dom().el("#page__sobre");
const pageContato = new Dom().el("#page__contato");
const pageFormulario = new Dom().el("#page__formulario");
const pageFormularioResposta = new Dom().el("#page__formulario-resposta");

if (pageHome) {
	// ★ Home
	new Dom().bodyClass("body__home");
	scriptsHome();
	animationHome();
} else if (pageSobre) {
	// ★ Sobre
	new Dom().bodyClass("body__sobre");
	animationSobre();
} else if (pageContato) {
	// ★ Contato
	new Dom().bodyClass("body__contato");
	animationContato();
} else if (pageFormulario) {
	// ★ Formulario
	new Dom().bodyClass("body__formulario");
	pageFormularioScripts();
} else if (pageFormularioResposta) {
	// ★ FormularioResposta
	console.log("ELSE IF");
	new Dom().bodyClass("body__formulario-resposta");
	pageFormularioScripts();
	pageFormularioRespostaScripts();

	function startAllChoices() {
		const allSelects = [
			...document.querySelectorAll("select[js-choices]"),
		]?.filter((select) => !select.hasAttribute("js-no-choices"));

		if (!allSelects) return;

		const listOfChoices = [...allSelects].map((i) => {
			const hasAttr = i.getAttribute("js-choices-props");
			const props = hasAttr ? JSON.parse(hasAttr) : {};

			return initChoices(i, {
				searchEnabled: false,
				searchChoices: false,
				itemSelectText: "Selecionar",
				noResultsText: "Nada Encontrado...",
				noChoicesText: "Sem opções para escolher",
				...props,
			});
		});

		window.listOfChoices = listOfChoices;
	}

	function initChoices(el, opts) {
		return new Choices(el, {
			silent: true,
			placeholder: true,
			searchResultLimit: 10,
			placeholderValue: 0,
			renderChoiceLimit: -1,
			itemSelectText: "Selecionar",
			noResultsText: "Nada Encontrado...",
			shouldSort: false,
			searchEnabled: true,
			searchChoices: true,
			// searchEnabled: false,
			// searchChoices: false,
			...opts,
		});
	}

	startAllChoices();
}

// SCROLL INTO VIEW BANNERS
const goTo = document.querySelector(
	"#banner-all-pages .banner-content .btn-go-to-section a"
);

if (goTo) {
	goTo.addEventListener("click", (e) => {
		e.preventDefault();
		const nextSection = goTo.closest("section").nextElementSibling;
		nextSection.scrollIntoView({ behavior: "smooth" });
	});
}

IMaskInit();

document.addEventListener("DOMContentLoaded", () => {
	document.body.classList.add("dcl");
});
