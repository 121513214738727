export default function globalAnimation() {
	// group-icons
	gsap.registerPlugin(ScrollTrigger);

	function bannerAllPages() {
		const isHome = document.body.classList.contains("body__home");
		if (isHome) return;
		// console.log()
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: "#banner-all-pages",
				start: "top+=50px bottom",
			},
		});
		//   gsap.set('.group-icons',{clipPath: "circle(0%)", opacity:1, scale: 1});

		//   tl.to('.group-icons #teste-svg',{ duration: 1 , opacity: 1, clipPath: "circle(100%)",scale: 1, repeat: -1, yoyo: true})
		//   tl.to('.group-icons #icon-banner',{ duration: 1 , opacity: 1, clipPath: "circle(100%)",scale: 1, repeat: -1, yoyo: true})
		gsap.set("#banner-all-pages .group-icons #icon-banner-2", {
			clipPath: "circle(40%)",
		});
		gsap.set("#banner-all-pages .banner-infos", {
			clipPath: "circle(0%)",
			opacity: 1,
		});

		tl.from("#banner-all-pages .banner-content .section-title > *", {
			y: 15,
			stagger: 0.15,
			opacity: 0,
			ease: Back.easeOut.config(1.7),
		})
			.fromTo(
				"#banner-all-pages .banner-content .col-2",
				{ clipPath: "circle(0%)" },
				{ clipPath: "circle(160%)" },
				"-=0.5"
			)
			.fromTo(
				"#banner-all-pages .banner-content  .img-card",
				{ clipPath: "inset(0 0 100% 0)" },
				{ duration: 1, clipPath: "inset(0 0 0% 0)" },
				"-=0.3"
			)
			.fromTo(
				"#banner-all-pages .banner-content  .img-linear",
				{ clipPath: "inset(0 100% 100% 0)" },
				{ duration: 1, clipPath: "inset(0 0% 0% 0)" },
				"<"
			)
			.fromTo(
				"#banner-all-pages .lines-ornamento",
				{ clipPath: "inset(0 100% 0 0)" },
				{ duration: 1, clipPath: "inset(0 0% 0% 0)" },
				"<"
			)

			.fromTo(
				"#banner-all-pages .banner-content  .group-icons",
				{ clipPath: "inset(0 100% 100% 0)" },
				{ duration: 1, clipPath: "inset(0 0% 0% 0)" },
				"<"
			)
			.to(
				"#banner-all-pages .banner-infos",
				{ duration: 2, opacity: 1, clipPath: "circle(100%)", delay: 0.5 },
				"<-=0.5"
			)
			.to(
				"#banner-all-pages .banner-content .btn-go-to-section",
				{
					duration: 2,
					y: 15,
					repeat: -1,
					yoyo: true,
				},
				1
			);

		gsap.to("#banner-all-pages .group-icons #icon-banner-2", {
			duration: 2,
			scale: 1.1,
			y: 2,
			ease: "power1.inOut",
			stagger: {
				// grid: [7,15],
				from: "random",
				amount: 1.5,
				repeat: -1,
				yoyo: true,
			},
		});

		//   const maxRot = 50;
		//         const setRot = gsap.quickSetter(".img-card", "rotationX", "deg");
		//         const setRot2 = gsap.quickSetter(".img-linear", "rotationY", "deg");

		//         gsap.set(".img-card", {transformOrigin: "center center"});
		//         gsap.set(".img-linear", {transformOrigin: "center center"});

		//         let getPercent;
		//         function resize() {
		//         getPercent = gsap.utils.mapRange(0, innerWidth, -1, 1);
		//         }

		//         window.addEventListener("mousemove", e => {
		//         const percent = getPercent(e.pageX);
		//         setRot(percent * maxRot);
		//         setRot2(percent * maxRot)
		//         });

		//     window.addEventListener("resize", resize);
		//     resize();

		//   .from('.group-icons #icon-banner-2',{  duration:1, clipPath: "circle(100%)", repeat: -1, stagger: 0.1, yoyo: true , ease: 'none'})
	}

	function ctaParcelas() {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: "#cta-whatsapp.parcela",
				start: "top+=50px 80%",
				end: "bottom",
				// markers: true,
				// once: false
			},
			defaults: { ease: Power1.easeOut },
		});
		tl.fromTo(
			"#cta-whatsapp.parcela",
			{ y: 100, opacity: 0 },
			{ y: 0, opacity: 1 }
		)
			.fromTo(
				"#cta-whatsapp.parcela .info",
				{ clipPath: "inset(0 100% 0 0)" },
				{ clipPath: "inset(0 0% 0 0)" },
				"-=0.2"
			)
			.fromTo(
				"#cta-whatsapp.parcela .btn-wrapper-with-icons",
				{ clipPath: "inset(0 100% 0 0)" },
				{ clipPath: "inset(0 0% 0 0)" },
				"-=0.2"
			);
	}

	function aSolucao() {
		// a-solucao
		// clip-path: inset(0 100% 0 0);
		gsap.set("#a-solucao .col-1", { clipPath: "inset(0 0% 0 0)" });
		// gsap.set('#a-solucao .col-2 .steps-wrapper .step', { opacity: 0})
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: "#a-solucao",
				start: "top 80%",
				// markers: true,
			},
			defaults: {},
		});

		tl.from("#a-solucao .col-1", {
			clipPath: "inset(0 100% 0 0)",
			opacity: 1,
			duration: 0.8,
			ease: "Expo.easeOut",
		}).from(
			"#a-solucao .col-2 .steps-wrapper .step",
			{ y: 20, stagger: 0.2, opacity: 0 },
			"-=0.2"
		);
	}
	function FAQ() {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: "#faq ",
				start: "top+=150px 80%",
				// markers: true
			},
		});

		tl.from("#faq .section-title > *", {
			stagger: 0.15,
			y: 15,
			opacity: 0,
		}).from(
			"#faq .grid-wrapper",
			{
				//    scrollTrigger: {
				//   trigger: "#faq .col-2",
				//   start: "top 80%",
				//   // markers: true
				// },
				y: 150,
				opacity: 0,
			},
			"<+=.2"
		);
	}
	document.addEventListener("DOMContentLoaded", function () {
		const banner = document.querySelector("#banner-all-pages");
		// hovecrBtns();
		banner && bannerAllPages();
		// ctaParcelas();
		// aSolucao();
		// FAQ();
	});
}
