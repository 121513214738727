export default function animationContato(){
    gsap.registerPlugin(ScrollTrigger)

    function formulario(){
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#contato .container",
                start: "top+=50px 80%",
              
               
              },    
          });
          tl
          .from('#contato .section-title  > *',{
            y: 15,
            stagger: 0.2,
            opacity: 0
          })
          .from('#contato .col-1 form  > *',{
            y: 15,
            stagger: 0.1,
            opacity: 0
          },'<+=0.2')
          .fromTo('#contato .col-2 .group-icons',{  clipPath: "circle(0%)", scale: 0.8}, { duration: 1.5,clipPath: "circle(100%)", scale: 1} ,'<')

          gsap.to('#contato .col-2 .img-wrapper' ,{   
            scrollTrigger: {
            trigger: "#contato .container",
            start: "top+=50px 80%",
            scrub: 2,
           
          },    
           scale: 1.1})
        //   .fromTo('#contato .col-2 .group-icons',{  clipPath: "circle(0%)"}, { clipPath: "circle(100%)",})
        //   .from('#contato .col-2 .img-wrapper' ,{ scale: 1.1})

        //   scrollTrigger: {
        //     trigger: trigger,
        //     start: "top center",
        //     end: "bottom top",
          
        //     scrub: 2
        //   }
    }

    function ctaWhatsapp(){
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: "#cta-whatsapp",
                start: "top-=50px bottom-=100px ",
              
                // markers: true
              },    
          });
          tl.fromTo('#cta-whatsapp .info-wrapper > *', { clipPath: 'inset(0 100% 0 0)', y: 10}, { stagger: 0.2 ,clipPath: 'inset(0 0% 0 0)', y: 0})
    }

    document.addEventListener("DOMContentLoaded", function (){
        formulario();  
        ctaWhatsapp(); 
    })
}