export default function menu() {
	const btnMobile = document.getElementById("btn-mobile");
	const headerEl = document.getElementById("header");
	const pageContato = document.querySelector("#page__contato");
	const pageFAQ = document.querySelector("#page__formulario");

	function toggleMenu(event) {
		if (event.type === "touchstart") event.preventDefault();
		const nav = document.getElementById("nav");

		nav.classList.toggle("active");
		headerEl.classList.toggle("menu-open");
		const active = nav.classList.contains("active");
		event.currentTarget.setAttribute("aria-expanded", active);

		if (active) {
			event.currentTarget.setAttribute("aria-label", "Fechar menu");
		} else {
			event.currentTarget.setAttribute("aria-label", "Abrir menu");
		}
	}

	if (btnMobile) {
		btnMobile.addEventListener("click", toggleMenu);
	}

	if (pageContato || pageFAQ) {
		headerEl.classList.add("fixo");
	}

	window.addEventListener("scroll", function (event) {
		if (pageContato) return;
		headerEl.classList.toggle("fixo", window.scrollY > 150);
	});
}
