import moment from "moment";

export default function scriptsHome() {
	// 'colocar scripts das respectivas paginas dentro de -scriptsPaginas-  e criar o arquivo exemplo: home.js - contato.js  --- e importar no main e executar em sua respectiva pagina '
	const mobile = window.matchMedia("(min-width: 1024px)").matches;

	const showToastify = (message, props) => {
		Toastify({
			...this.toastDefault,

			text: message,
			duration: 3500,
			...props,
		}).showToast();
	};

	function slideToSimulador() {
		const { hash } = window.location;
		if (!hash.includes("#simulador")) return;
		console.log('scroll ocorreu')

		const section = document.querySelector("#simulador");

		section.scrollIntoView({
			top:0,
			behavior: 'smooth'
		})
	}

	document.addEventListener("DOMContentLoaded", () => {
		slideToSimulador();
	});

	// PROGRESS  1
	const progressCircle = document.querySelectorAll(
		"#banner .autoplay-progress svg"
	);
	// const progressContent = document.querySelector("#banner .autoplay-progress span");

	// PROGRESS 2
	const progressCircle2 = document.querySelectorAll(
		"#videos .autoplay-progress svg"
	);
	// const progressContent2 = document.querySelector("#videos .autoplay-progress span");

	const swiperBanner = new Swiper(".swiper-banner", {
		slidesPerView: 1,
		effect: "fade",
		fadeEffect: {
			//   crossFade: true
		},
		autoplay: {
			delay: 8000,
			disableOnInteraction: false,
		},
		on: {
			autoplayTimeLeft(s, time, progress) {
				if (progressCircle) {
					progressCircle.forEach((i) =>
						i.style.setProperty("--progress", 1 - progress)
					);
				}
				// progressContent.textContent = `${Math.ceil(time / 1000)}s`;
			},
		},
	});

	// const swiperBannerInfos = new Swiper('.swiper-infos-banner', {
	//     slidesPerView: 'auto',
	//     loop: true,
	//     allowTouchMove: false,
	//     alowtouch: false,
	//     speed: 2000,
	//     // freemode: true,
	//     breakpoints:{
	//         1024:{
	//             slidesPerView: 8
	//         }
	//     },
	//     autoplay: {
	//         delay:0,
	//         pauseOnMouseEnter: true,
	//         disableOnInteraction: false
	//       },
	// })

	const swiperThreeSteps = new Swiper(".swiper-steps-card", {
		slidesPerView: 1.2,
		spaceBetween: 16,
		breakpoints: {
			768: {
				slidesPerView: 2.2,
				spaceBetween: 16,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 16,
			},
			1200: {
				slidesPerView: 3,
				spaceBetween: 32,
			},
		},
	});

	const swiperComoGastar = new Swiper(".swiper-como-gastar", {
		slidesPerView: 1.2,
		spaceBetween: 16,
		breakpoints: {
			768: {
				slidesPerView: 2.2,
				spaceBetween: 16,
			},
			1024: {
				slidesPerView: 3.3,
				spaceBetween: 16,
			},
			1200: {
				slidesPerView: 4,
				spaceBetween: 16,
			},
		},
	});

	const swiperVideos = new Swiper(".swiper-videos", {
		slidesPerView: 1,
		spaceBetween: 20,
		direction: mobile ? "vertical" : "horizontal",

		speed: 1100,
		loop: true,
		// autoHeight: true,

		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
		},

		scrollbar: {
			el: ".swiper-scrollbar",
			// hide: true,
		},

		on: {
			autoplayTimeLeft(s, time, progress) {
				if (progressCircle2) {
					progressCircle2.forEach((circle) => {
						circle.style.setProperty("--progress2", 1 - progress);
					});
				}
				// progressContent2.textContent = `${Math.ceil(time / 1000)}s`;
			},

			init: (swiper) => {
				// const totalSlides = document.querySelectorAll('.swiper-videos .swiper-slide').length
				const totalSlides = swiper.slides.length;
				let totalGap =
					swiper.passedParams.spaceBetween *
					(swiper.passedParams.slidesPerView - 1);
				const slidesHeight = Array.from(
					document.querySelectorAll(".swiper-videos .video-slide")
				);

				let heights = slidesHeight.map((s) => s.offsetHeight);
				let heightest = Math.max(...heights);

				if (!mobile) return;

				let containerHeight =
					swiper.passedParams.slidesPerView * heightest + totalGap;
				slidesHeight.forEach((s) => (s.style.height = containerHeight + "px"));
				swiper.el.style.height = containerHeight + "px";
			},
		},
	});

	let currentStep = 0;
	function stepsSimule() {}
	const btnNextStep = document.querySelectorAll(
		"#simulador .go-to-next-step button"
	);
	const step1 = document.querySelector("#simulador .step-1");
	const step2 = document.querySelector("#simulador .step-2");
	const step3 = document.querySelector("#simulador .step-3");
	const step4 = document.querySelector("#simulador .step-4");
	step2.style.display = "none";
	step3.style.display = "none";
	step4.style.display = "none";
	const inputSaldo = document.querySelector("#simulador .step-1 input");

	if (btnNextStep) {
		btnNextStep.forEach((btn) => {
			btn.addEventListener("click", (e) => {
				console.log(e.target.closest(".step").classList);
				if (e.target.closest(".step").classList.contains("step-1")) {
					if (!step1.querySelector("input").value) {
						step1
							.querySelector("input")
							.closest(".input-wrapper")
							.classList.add("error");
						step1.querySelector("input").focus();
						return;
					}
					step1.style.display = "none";
					step2.style.display = "block";
				}

				if (e.target.closest(".step").classList.contains("step-2")) {
					if (!step2.querySelector("input").value) {
						step2
							.querySelector("input")
							.closest(".input-wrapper")
							.classList.add("error");
						step2.querySelector("input").focus();
						return;
					}
					step2.style.display = "none";
					step3.style.display = "block";
				}
				if (e.target.closest(".step").classList.contains("step-4")) {
					resetSimulador();
				}
			});
		});
	}
	let data = {};
	let dateEN;
	let valor;
	const formulario = document.querySelector("#formulario");
	formulario.addEventListener("input", function (event) {
		console.log(formulario);
		const corban = formulario.getAttribute("data-corban");
		const taxa = formulario.getAttribute("data-taxa");
		if (event.target.name == "birth_date") {
			dateEN = moment(event.target.value, "DD/MM/YYYY").format("YYYY-MM-DD");
		}

		if (event.target.name == "saldo_autoatendimento") {
			valor = Number(
				String(event.target.value)
					.replace(/[A-Z]\W|[.]/g, "")
					.replace(",", ".")
			);
		}

		data = {
			...data,
			[event.target.name]: event.target.value,
			corban,
			saldo_autoatendimento: valor,
			birth_date: dateEN,
			taxa_juros: taxa,
		};

		console.log({data});
	});

	formulario.addEventListener("submit", async function (event) {
		event.preventDefault();
		if (!step3.querySelector("input").value) {
			step3
				.querySelector("input")
				.closest(".input-wrapper")
				.classList.add("error");
			step3.querySelector("input").focus();
			return;
		}
		await fetch(`/api/naturalperson/`, {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
				Authorization: `Token ${process.env.TOKEN}`,
			},
			mode: "cors",
		})
			.then(async (response) => {
				if (!String(response.status).startsWith(2)) {
					showToastify(
						`Erro na comunicação com servidor, tente novamente mais tarde.`,
						{
							style: {
								background: "#f80000",
							},
						}
					);
					resetSimulador();
					return;
				}
				response = await response.json();

				const liberado = new Intl.NumberFormat("pt-br", {
					style: "currency",
					currency: "BRL",
				}).format(response.calcular_valor_liberado_fgts);
				step3.style.display = "none";
				step4.style.display = "flex";
				step4.querySelector(
					".step-header .saque-liberado-container p"
				).innerHTML = liberado;
			})
			.catch((err) => {
				console.log(err);
				showToastify(
					`Erro na comunicação com servidor, tente novamente mais tarde.`,
					{
						style: {
							background: "#f80000",
						},
					}
				);
				resetSimulador();
				return;
			});
	});

	const resetSimulador = () => {
		step1.querySelector("input").value = "";
		step2.querySelector("input").value = "";
		step3.querySelector("input").value = "";
		step1.style.display = "block";
		step2.style.display = "none";
		step3.style.display = "none";
		step4.style.display = "none";
	};

	const totaldesejado = document.querySelector("input[search-total-desejado]");
	const valor_minimo = +totaldesejado.getAttribute("valor-minimo")
	.replace(",", ".");
	const btn = document.querySelector("button[data-verifica-fgts]");

	totaldesejado?.addEventListener("input", (e) => {
		let valDesejado = Number(
			String(e.target.value)
				.replace(/[A-Z]\W|[.]/g, "")
				.replace(",", ".")
		);
		if (isNaN(valDesejado) || valDesejado < Number(valor_minimo)) {
			btn.disabled = true;
			btn.style = "pointer-events: none; opacity: 0.7";
		} else {
			btn.disabled = false;
			btn.style = "pointer-events: auto; opacity: 1";
		}

		// btn.addEventListener("click", (el) => {
		// 	if (!btn.disabled) {
		// 		document.location.href = `/antecipe_fgts/?valor_desejado=${valDesejado}#form`;
		// 	}
		// });
	});
}