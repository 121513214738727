export default function animationSobre(){
    gsap.registerPlugin(ScrollTrigger)

    // bancos
    function bancos(){
        let tl = gsap.timeline({
          scrollTrigger: {
              trigger: "#bancos .container",
              start: "top+=50px 80%",
            //   markers: true
            },    
        });
    
        tl
        .fromTo('#bancos',{ clipPath: "inset(0 100% 0 0)"} ,{  duration: 1,  clipPath: "inset(0 0% 0 0)"}, )
        .fromTo('#bancos .bancos-wrapper',{ clipPath: "inset(0 100% 0 0)"} ,{  duration: 1,  clipPath: "inset(0 0% 0 0)"}, '-=0.5')
        // .from('#bancos', {stagger: 0.15, y: 15, opacity: 0, })
      
        
      }
        document.addEventListener("DOMContentLoaded", function (){
            // hovecrBtns();
         
            bancos();
          })
}